// Workaround for issue: https://github.com/jpedroschmitz/rocketdocs/issues/114

import React from "react"
import appImage from "./vitalapp.jpg"

export default function LandingImage(props) {
  return <>
    <img src={appImage} alt="a computer showing the VitalApp software, with VitalVials attache" width="700" />
  </>
}
